import React from "react";
import Map from "../components/maps";
import "./portfolio.scss";

const Portfolio = () => {
  return (
    <>
      <Map className="map" />
    </>
  );
};

export default Portfolio;
