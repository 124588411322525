export const FACEBOOK_WEDDING_ALBUMS_START = "FACEBOOK_WEDDING_ALBUMS_START";
export const FACEBOOK_WEDDING_ALBUMS_SUCCESS =
  "FACEBOOK_WEDDING_ALBUMS_SUCCESS";
export const FACEBOOK_WEDDING_ALBUMS_FAIL = "FACEBOOK_WEDDING_ALBUMS_FAIL";

export const FACEBOOK_ENGAGEMENT_ALBUMS_START =
  "FACEBOOK_ENGAGEMENT_ALBUMS_START";
export const FACEBOOK_ENGAGEMENT_ALBUMS_SUCCESS =
  "FACEBOOK_ENGAGEMENT_ALBUMS_SUCCESS";
export const FACEBOOK_ENGAGEMENT_ALBUMS_FAIL =
  "FACEBOOK_ENGAGEMENT_ALBUMS_FAIL";

export const FACEBOOK_GET_PHOTOS_START = " FACEBOOK_GET_PHOTOS_START";
export const FACEBOOK_GET_PHOTOS_SUCCESS = "FACEBOOK_GET_PHOTOS_SUCCESS";
export const FACEBOOK_GET_PHOTOS_FAIL = "FACEBOOK_GET_PHOTOS_FAIL";
